<template>
    <div class="bottom">
        <div class="content">
            <div class="left">
                <Activity orient="left"></Activity>
            </div>
            <div class="cen">
                <div class="cen-left">
                    <Shop orient="left"></Shop>
                </div>
                <div class="cen-right">
                    <Varieties orient="right"></Varieties>
                </div>
            </div>
            <div class="right">
                <Facility orient="right"></Facility>
            </div>
        </div>
    </div>
</template>

<script>
import Activity from './Activity.vue'
import Facility from './Facility.vue'
import Shop from './Shop.vue'
import Varieties from './Varieties.vue'
export default {
    components: {
        Activity,
        Facility,
        Shop,
        Varieties
    }
}
</script>

<style lang="scss" scoped>
.bottom {
    width: 100%;
    height: 310px;
    box-sizing: border-box;
    padding: 0 10px;

    .content {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .left,
        .right {
            width: 450px;
            height: 100%;
        }

        .left {
            //margin-right: 0.06vw;
            text-align: center;
        }

        .right {
            //margin-left: 0.06vw;
        }

        .cen {
            width: 1010px;
            height: 100%;
            display: flex;
            justify-content: space-between;

            .cen-left,
            .cen-right {
                width: 450px;
                height: 100%;
            }

            .cen-left {
                //margin-right: 0.7vw;
            }
        }
    }
}
</style>
<template>
  <TemplateSlot :orient="orient" :name="name">
    <div id="Facility">
      <div v-for="(item, index) in facilityInfo" :key="index">
        <div :class="index % 2 == 1 ? 'top_itemr0' : 'top_item'">
          <div class="pic_box"><img :src="item.url" alt="" /></div>
          <div class="content">
            <div class="title">{{ item.name }}</div>
            <div class="value">{{ item.count }}<small>个</small></div>
          </div>
        </div>
      </div>
    </div>
  </TemplateSlot>
</template>

<script>
import { mapState } from 'vuex';
import TemplateSlot from './TemplateSlot.vue';
export default {
  components: {
    TemplateSlot
  },
  data() {
    return {
      name: '基础服务信息'
    }
  },
  computed: {
    ...mapState(['facilityInfo'])
  },
  props: ['orient']
}
</script>

<style lang="scss" scoped>
#Facility {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .top_item {
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .top_itemr0 {
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  .pic_box {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 120px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 5px;

    .title {
      font-size: 16px;
      text-align: left;
      color: #90daff;
      letter-spacing: 2px;
      font-family: "content";
    }

    .value {
      height: 50px;
      width: 100px;
      text-align: left;
      font-size: 24px;
      color: #fff;
      letter-spacing: 2px;
      font-family: "DS";

      small {
        font-size: 16px;
      }
    }
  }

}</style>
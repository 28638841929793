import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './scss/index.scss'
import 'video.js/dist/video-js.css'
import * as echarts from "echarts"
import axios from 'axios'
import store from './store'
import youcha from "./libs/theme-youcha"


echarts.registerTheme('youcha', youcha)
axios.defaults.baseURL = 'https://htyc.higozj.com/app-api/api/front/'
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')

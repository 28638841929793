<template>
  <TemplateSlot :orient="orient" :hasTitle="true" :name="name">
    <div id="Shop">
      <div class="head">
        <div class="no">序号</div>
        <div class="col">企业名称</div>
        <div class="date">注册日期</div>
      </div>
      <div style="width: 100%; height: 20.9vh; position: relative; overflow: hidden;">
        <div class="monitor pannel">
          <div class="inner">
            <div class="content" style="display: block">
              <div id="marquee-view">
                <div class="marquee">
                  <div v-for="(item, index) in shopGroup" :key="index">
                    <div class="row">
                      <div class="no">{{ index + 1 }}</div>
                      <div class="col">{{ item.shopName }}</div>
                      <div class="date">{{ item.date }}</div>
                    </div>
                  </div>
                </div>
                <div class="marquee">
                  <div v-for="(item, index) in shopGroup" :key="index">
                    <div class="row">
                      <div class="no">{{ index + 1 }}</div>
                      <div class="col">{{ item.shopName }}</div>
                      <div class="date">{{ item.date }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </TemplateSlot>
</template>

<script>
import { mapState } from "vuex";
import TemplateSlot from "./TemplateSlot.vue";
export default {
  components: {
    TemplateSlot,
  },
  props: ["orient"],
  data() {
    return {
      name: '商户统计',
      reset: false,
    }
  },
  computed: {
    ...mapState(['shopGroup'])
  },
  mounted() {
    this.startmarquee(48, 20, 0, "marquee-view");
  },
  methods: {
    startmarquee(lh, speed, delay, marqueeObj) {
      //lh---每行列表的高度，speed---滚动的速度，delay---间隔多久滚动一次,marqueeObj---需要实现这个效果的id
      var p = false;
      var t = null;
      var o = document.getElementById(marqueeObj);
      o.style.marginTop = 0;
      o.onmouseover = function () {
        p = true;
      }; //鼠标移入，停止滚动
      o.onmouseout = function () {
        p = false;
      }; //鼠标移出，继续滚动

      function start() {
        t = setInterval(scrolling, speed); //定时器，自动滚动
        if (!p) o.style.marginTop = parseInt(o.style.marginTop) - 1 + "px";
      }

      function scrolling() {
        if (parseInt(o.style.marginTop) % lh != 0) {
          o.style.marginTop = parseInt(o.style.marginTop) - 1 + "px";
          if (Math.abs(parseInt(o.style.marginTop)) >= o.scrollHeight / 2)
            o.style.marginTop = 0;
        } else {
          clearInterval(t);
          setTimeout(start, delay);
        }
      }
      setTimeout(start, delay);
    },
  }
};
</script>

<style lang="scss" scoped>
#Shop {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .head {
    display: flex;
    background: rgba(208, 222, 238, 0.1);
    color: #ffe7cd;
    font-weight: bold;
    height: 40px;
    line-height: 40px;

    .col {
      flex: 1;
      height: 40px;
      //text-align: center;
      box-sizing: border-box;
      font-size: 14px;
      text-align: left;
    }

    .no {
      width: 50px;
      height: 40px;
      text-align: center;
      box-sizing: border-box;
      font-size: 14px;
    }

    .date {
      width: 100px;
      height: 40px;
      text-align: center;
      box-sizing: border-box;
      font-size: 14px;
    }
  }

  .monitor {
    width: 100%;
    height: 100%;
    color: #fff;
    position: absolute;

    #marquee-view {
      width: 100%;
      height: 300px;
      margin-top: 20%;
      position: relative;
    }

    .row {
      width: 100%;
      display: flex;
      height: 48px;
      line-height: 48px;

      .col {
        display: block;
        flex: 1;
        height: 48px;
        text-align: left;
        box-sizing: border-box;
        font-size: 16px;
        font-family: 'content';
      }

      .no {
        width: 50px;
        height: 40px;
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
        color: #34FFBF;
        font-weight: bold;
      }

      .date {
        width: 100px;
        height: 40px;
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
      }
    }

    .row:hover {
      background: rgba(208, 222, 238, 0.1);
    }
  }
}
</style>
<template>
    <div id="openmap"></div>
</template>
<script>
import { mapState } from "vuex";
import { Map, View, Feature } from "ol";
import * as olProj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Vector as VectorSource } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import HeatmapLayer from "ol/layer/Heatmap";
import Point from 'ol/geom/Point.js';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
export default {
    data() {
        return {
            view: {
                htyc: null,
                htyckpy: null,
                cshtc: null
            },
            camera: {
                htyckpy: null,
                cshtc: null,
            },
            map: null,
            hotlayer: null,
            tileLayer: {
                htyc: null,
                htyckpy: null,
                cshtc: null
            },
            extent: {
                htyc: null,
                htyckpy: null,
                cshtc: null
            },
            active: 'htyc',
            plantIcon: null,
            cameraIcon: null,
            vectorLayer: null,
            markerSource: null,
            mainSpotsData: [], //主图数据
        }
    },
    computed: {
        ...mapState(['spotsData', 'spotsData2', 'spotsData3', 'camerasData', 'scenicData']),
        tileData() {
            if (this.scenicData) {
                return this.scenicData
            }
            return {}
        }
    },
    watch: {
        scenicData(val) {

            if (val && this.view.htyc == null) {

                this._initMapView()
            }
        },
        spotsData2(val) {
            if (val && this.tileData[this.active].id == 2) {
                this.showHotspots()
            }
        },
        // spotsData3(val) {
        //     if (val && this.tileData[this.active].id == 3) {
        //         this.showHotspots()
        //     }
        // },
    },
    methods: {

        init() {
            this.map = new Map({
                target: 'openmap',
                controls: []
            })
            this._initBaseMarkerIcons();
            this.supportVectorLayer();
            this.listenClickFeatureEvent();
        },
        _initMapView() {
            this._createView("htyc")
            this._createView("htyckpy")
            this._createView("cshtc")

            this._createTileLayer("htyc")
            this._createTileLayer("htyckpy")
            this._createTileLayer("cshtc")

            this.swtichTileLayer("htyc")
        },
        _initBaseMarkerIcons() {
            this.plantIcon = new Icon({
                anchor: [0.5, 1],
                height: 50,
                width: 50,
                src: "/map-marker1.png",
            })
            this.cameraIcon = new Icon({
                anchor: [0.5, 1],
                height: 50,
                width: 50,
                src: "/map-camera.png",
            })
        },
        supportVectorLayer() {
            this.markerSource = new VectorSource();
            this.heatmapLayer = new HeatmapLayer({
                source: this.markerSource,
                zIndex: 999,
                visible: false,
                blur: parseInt(20, 10),
                radius: parseInt(20, 10),
                weight: function (feature) {
                    const data = feature.get('userSpotData');
                    if (data.viewTimes) {
                        return parseFloat(data.viewTimes);
                    }
                    return 0;
                },
            });
            //初始化一个矢量图层，给标注feature使用
            this.vectorLayer = new VectorLayer({
                source: this.markerSource,
                zIndex: 990,
            })
            this.map.addLayer(this.vectorLayer)
            this.map.addLayer(this.heatmapLayer)
            return this
        },
        _createView(name) {
            if (name && this.tileData[name]) {
                const tile = this.tileData[name]
                const coord = olProj.fromLonLat(tile.center);
                const extent = this._initExtent(tile.southWest, tile.northEast)
                this.view[name] = new View({
                    center: coord,
                    zoom: tile.zoom,
                    maxZoom: tile.zooms[1],
                    minZoom: tile.zooms[0],
                    constrainOnlyCenter: true,
                    constrainResolution: true,
                    zoomFactor: 2,
                    extent: extent,
                })
                this.extent[name] = extent
            }
        },
        _createTileLayer(tile) {
            if (tile && this.tileData[tile]) {
                const layer = new TileLayer({
                    visible: false,
                    source: new XYZ({
                        url: this.tileData[tile].url,
                    })
                });
                this.map.addLayer(layer)
                this.tileLayer[tile] = layer
            }
        },
        _initExtent(southEastLonLat, northWestLonLat) {
            const coordSouthEast = southEastLonLat ? olProj.fromLonLat(southEastLonLat) : undefined
            const coordNorthWest = northWestLonLat ? olProj.fromLonLat(northWestLonLat) : undefined
            if (coordNorthWest && coordSouthEast) {
                return [...coordSouthEast, ...coordNorthWest]
            }
            return null;
        },
        swtichTileLayer(name) {
            for (let layer in this.tileLayer) {
                if (layer == name) {
                    this.tileLayer[layer].setVisible(true)
                } else {
                    this.tileLayer[layer].setVisible(false)
                }
            }
            this.active = name;
            this.map.setView(this.view[name])
            this.showHotspots();
            this.setFitView();
        },
        showHeatMap() {
            this.heatmapLayer.setVisible(true)
            this.vectorLayer.setVisible(false)
        },

        hideHeatMap() {
            this.heatmapLayer.setVisible(false)
            this.vectorLayer.setVisible(true)
        },
        showHotspots() {
            console.log(this.spotsData3, 'this.spotsData3============');
            this.heatmapLayer.setVisible(false)
            this.vectorLayer.setVisible(true)
            let id = this.tileData[this.active].id
            console.log(id, 'id===========');
            let poiList = null
            if (id == 2) {
                poiList = this.spotsData2
                
            } else if(id == 3){
                console.log(111111111111111);
                poiList = this.spotsData3
                
            } else {
                console.log(2222222222222);
                poiList = this.spotsData.filter(spot => {
                    return spot.scenicId == id
                })
            }
            console.log(poiList, 'poiList==================');
            let textStyle = {
                font: "16px sans-serif",
                textAlign: "center",
                textBaseline: "middle",
                fill: new Fill({
                    color: '#000'
                }),
                backgroundFill: new Fill({
                    color: '#fff'
                }),

            }
            const markerList = poiList.map((spot) => {
                const { id, name, poi, virPoi, isVirtual, markerIcon } = spot
                const lonlat = isVirtual ? virPoi : poi
                textStyle.padding = [2, 5, 2, 5]
                textStyle.offsetY = 10
                textStyle.text = name

                this.plantIcon = new Icon({
                    anchor: [0.5, 1],
                    height: 50,
                    width: 50,
                    src: markerIcon,
                })
                let markerStyle = new Style({
                    image: this.plantIcon,
                    text: new Text(textStyle)
                })
                let coordinate = olProj.fromLonLat(lonlat.split(","))
                let feature = new Feature({
                    id: id,
                    geometry: new Point(coordinate),
                })
                feature.set("userSpotData", spot);
                feature.set("type", "spot");
                feature.setStyle(markerStyle)
                return feature
            })
            this.markerSource.clear()
            this.markerSource.addFeatures(markerList)

        },
        showCameras() {
            this.heatmapLayer.setVisible(false)
            this.vectorLayer.setVisible(true)
            let id = this.tileData[this.active].id
            let poiList = this.camerasData.filter(spot => {
                return spot.scenicId == id
            })
            let textStyle = {
                font: "16px sans-serif",
                textAlign: "center",
                textBaseline: "middle",
                fill: new Fill({
                    color: '#000'
                }),
                backgroundFill: new Fill({
                    color: '#fff'
                }),
            }
            const markerList = poiList.map((spot) => {
                const { code, poi, deviceName } = spot
                textStyle.padding = [2, 5, 2, 5]
                textStyle.offsetY = 10
                textStyle.text = deviceName
                let markerStyle = new Style({
                    image: this.cameraIcon,
                    text: new Text(textStyle)
                })
                let coordinate = olProj.fromLonLat(poi.split(","))
                let feature = new Feature({
                    id: code,
                    geometry: new Point(coordinate),
                })
                feature.set("userSpotData", spot);
                feature.set("type", "camera");
                feature.setStyle(markerStyle)
                return feature
            })
            this.markerSource.clear()
            this.markerSource.addFeatures(markerList)

        },
        setFitView(duration = 800) {
            const name = this.active
            const bounds = this.extent[name];
            this.map.getView().fit(bounds, {
                'duration': duration,
                'padding': [-50, -50, -50, -50],

            })
        },
        listenClickFeatureEvent() {
            this.map.on("click", (evt) => {
                const features = this.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
                    return feature
                })
                let userSpotData
                let type = 'spot';
                if (features) {
                    let targetFeature = features
                    userSpotData = targetFeature.get("userSpotData")
                    type = targetFeature.get("type")
                }
                // console.log(userSpotData, 1111111111111);
                if(userSpotData.name == '油茶科普物种园'){
                    return this.$emit('mapSwitching', 'cshtc')
                } else if(userSpotData.name == '油茶公园'){
                    return this.$emit('mapSwitching', 'htyckpy')
                } else if(!userSpotData.mainPic  && !userSpotData.detail && !userSpotData.url){
                    return
                }

                if (type == 'spot') {
                    this.$emit("openHotspot", userSpotData);
                } else {
                    this.$emit("openVideo", userSpotData);
                }
            })
        }

    },

    mounted() {
        this.init()
    },
}
</script>
<style>
#openmap {
    width: 100%;
    height: 100%;
    background-image: none;
    background-color: transparent !important;
}

.amap-marker-label {
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    background-image: url("../assets/marker-bg.png");
    background-size: cover;
    background-color: #3576bc;
}

.infowindow {
    /* width: 8.5vw; */
    box-sizing: border-box;
}

.infowindow_1 {
    /* width: 13.9vw; */
    box-sizing: border-box;
}

.map_title_box {
    /* width: 100%; */
    height: 3.3vh;
    background: #0c3965;
    box-sizing: border-box;
    padding: 0.4vh 0.6vw;
    display: flex;
    align-items: center;
}

.map_title_icon_box {
    width: 1.1vh;
    height: 1.1vh;
    margin-right: 0.4vh;
}

.map_title {
    height: 1.6vh;
    font-family: "content";
    text-align: left;
    font-size: 0.8vw;
    color: #fff;
}

.map_title_icon {
    width: 100%;
    height: 100%;
}

.map_content {
    width: 100%;
    background: #081d34;
    box-sizing: border-box;
    padding: 0.5vw;
    color: #fff;
    font-family: "content";
    line-height: 1.4vw;
    text-align: left;
    font-size: 1.3vh;
}

.map_content_img {
    width: 100%;
    background: #081d34;
    box-sizing: border-box;
    padding: 0.5vw;
    display: flex;
}

.map_img {
    width: 6.9vw;
    height: 14.4vh;
}
</style>
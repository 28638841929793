<template>
  <TemplateSlot :orient="orient" :name="name" height="300px">
    <div id="QrRanking">
      <div class="top">
        <div class="sum top_item">
          <div class="pic_box"><img src="@/assets/icon/icon2.png" alt="" /></div>
          <div class="content">
            <div class="title">二维码总数</div>
            <div class="value">{{ Number(qrCodeSum) }}</div>
          </div>
        </div>
        <div class="times top_item">
          <div class="pic_box"><img src="@/assets/icon/icon1.png" alt="" /></div>
          <div class="content">
            <div class="title">扫码次数</div>
            <div class="value">{{ Number(scanNumber) }}</div>
          </div>
        </div>
      </div>
      <div class="ranking">
        <div class="title">
          <div class="text">扫码排行</div>
        </div>
        <div class="content" id="scanning" ref="scanning" style="width: 100%; height: 135px"></div>
      </div>
    </div>
  </TemplateSlot>
</template>

<script>
import { mapState } from "vuex";
import TemplateSlot from "./TemplateSlot.vue";
export default {
  components: {
    TemplateSlot,
  },
  data() {
    return {
      qrCodeSum: '',
      scanNumber: '',
      scanRanking: [],
      name: '一物一码讲解',
      myChart: null,
    }
  },
  computed: {
    ...mapState(['scanningGroup'])
  },
  props: ["orient"],
  mounted() {
    this.myChart = this.$echarts.init(this.$refs.scanning, 'youcha');
    this.$EventBus.$on('resizeWindow', () => {
      this.myChart.resize()
    })
    this.scanning(this.scanRanking)
  },
  watch: {
    scanningGroup(newVal) {
      if (newVal) {
        //this.scanningGroup = newVal
        this.qrCodeSum = newVal.qrCodeSum
        this.scanNumber = newVal.scanNumber
        this.scanRanking = newVal.scanRanking
        this.scanning(this.scanRanking)
      }
    }
  },
  methods: {
    scanning(dataInfo) {

      this.myChart.clear()

      let titleList = []
      let dataList = []

      if (dataInfo) {
        dataInfo.forEach(el => {
          titleList.push(el.name)
          dataList.push(Number(el.count))
        })
      }
      //给每一项加上排名
      titleList.forEach((el, index) => {
        titleList[index] = `TOP${index + 1}\r` + " " + el
      })
      //数据
      var color = [
        "rgba(248,195,248",
        "rgba(100,255,249",
        "rgba(135,183,255"
      ];

      let lineY = [];
      for (var i = 0; i < titleList.length; i++) {
        var x = i;
        if (x > color.length - 1) {
          x = color.length - 1;
        }
        var data = {
          name: titleList[i],
          color: color[x] + ")",
          value: dataList[i],
          itemStyle: {
            normal: {
              show: true,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: color[x] + ", 0.3)",
                  },
                  {
                    offset: 1,
                    color: color[x] + ", 1)",
                  },
                ],
                false
              ),
              barBorderRadius: 10,
            },
            emphasis: {
              shadowBlur: 15,
              shadowColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        };
        lineY.push(data);
      }

      let option = {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "item",
        },
        grid: {
          borderWidth: 0,
          top: "10%",
          left: "5%",
          right: "15%",
          bottom: "3%",
        },
        color: color,
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: false,
            },
            data: dataList,
          },
          {
            type: "category",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              inside: false,
              textStyle: {
                color: "#fff",
                fontSize: "12",
                fontWight: 'bold',
                fontFamily: 'content',
              },
              formatter: function (val) {
                return `${val}` + '次';
              },
            },
            splitArea: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            data: dataList.reverse(),
          },
        ],
        xAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            zlevel: 2,
            barWidth: "5px",
            data: lineY,
            animationDuration: 1500,
            label: {
              normal: {
                color: "#fff",
                show: true,
                position: [0, "-17px"],
                textStyle: {
                  fontSize: 12,
                  fontWight: 'bold',
                  fontFamily: 'content'
                },
                formatter: function (a, b) {
                  return a.name;
                },
              },
            },
          },
        ],
        animationEasing: "cubicOut",
      };

      this.myChart.setOption(option, true);
    },
  },
};
</script>

<style lang="scss" scoped>
#QrRanking {
  width: 100%;
  height: 100%;

  .top {
    box-sizing: border-box;
    padding-left: 10px;
    width: 100%;
    height: 125px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;

    .sum {
      margin-right: 0;
    }

    .top_item {
      width: 200px;
      height: 100px;
      display: flex;

      .pic_box {
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .content {
        width: 125px;
        height: 100%;
        box-sizing: border-box;
        padding-top: 0.4vh;
        padding-left: 0.9vh;

        .title {
          font-size: 16px;
          color: #90daff;
          text-align: left;
          margin-bottom: 10px;
          line-height: 50px;
          letter-spacing: 2px;
          font-family: "content";
        }

        .value {
          height: 30px;
          width: 80px;
          text-align: left;
          line-height: 30px;
          margin-left: 5px;
          font-size: 30px;
          color: #fff;
          //letter-spacing: 0.3vh;
          font-family: "DS";
        }
      }
    }
  }

  .ranking {
    width: 100%;
    height: 16.2vh;

    .title {
      width: 100%;
      height: 40px;
      background: rgba(208, 222, 238, 0.1);
      display: flex;
      align-items: center;

      .text {
        //width: 6.3vh;
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        color: #ffe7cd;
        margin-left: 10px;

      }
    }
  }
}
</style>
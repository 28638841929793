<template>
  <div class="home">
    <!-- <HeaderView></HeaderView> -->
    <MainShow></MainShow>
    <MainBottom></MainBottom>
  </div>
</template>

<script>

// import HeaderView from '@/components/HeaderView.vue';
import MainShow from '@/components/MainShow.vue'
import MainBottom from '@/components/MainBottom.vue'
export default {
  data() {
    return {
      MainShow: {},
      MainBottom: {}
    }
  },
  components: {
   // HeaderView,
    MainShow,
    MainBottom
  },
  methods: {
    loadData() {
      this.$store.dispatch('getData')
      this.$store.dispatch('getWeather')
      this.$store.dispatch('getScenicList')
      this.$store.dispatch('getSpotList')
      this.$store.dispatch('getSpotList2')
      this.$store.dispatch('getSpotList3')
      this.$store.dispatch('getYcSpotList')
      this.$store.dispatch('getCameraList')
    }
  },
  mounted() {
    this.loadData()

    setInterval(() => {
      this.$EventBus.$emit('updateGlobalData')
    }, 60000)

    this.$EventBus.$on("updateGlobalData", () => {
      this.loadData()
    })

    window.onresize = () => {
      this.$EventBus.$emit("resizeWindow")
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 1010px;
  box-sizing: border-box;
  background: url('https://hgxc-cdn.higozj.com/screen/1.png') no-repeat;
  background-size: cover;
}
</style>
<template>
    <div class="hotspot" v-if="showInfo">
        <div class="hotspot_content">
            <div class="title">
                <div class="title_content">{{ hotspot.name }}</div>
                <div class="close_btn" @click="handleClose">
                    <img src="../assets/dialog-close.png" alt="">
                </div>
            </div>
            <div class="content">
                <img :src="hotspot.mainPic">
                <div class="detail" v-html="hotspot.detail">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hotspot: Object,
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showInfo: this.show,
            currentIndex: 0
        }
    },
    watch: {
        show(val) {
            this.showInfo = val
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:show', false)
        },

        tabSelect(index) {
            this.currentIndex = index
        }
    }
}
</script>
<style lang="scss">
.hotspot {
    position: absolute;
    width: 100%;
    z-index: 9999;
    top: 16vh;
    left: 0;

    img {
        display: block;
        max-width: 480px !important;
        margin: auto;
    }

    .hotspot_content {
        position: relative;
        margin: auto;
        width: 600px;
        height: 500px;
        background-image: url("../assets/dialog-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 20px 30px 30px 15px;

        .title {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            .title_content {
                //height:100px;
                line-height: 50px;
                font-family: title;
                font-size: 18px;
                text-align: left;
                color: #fff;
                padding-left: 36px;
                font-weight: bold;
            }


            .close_btn {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .content {
            width: 540px;
            height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: wheat;
            padding: 15px;
            text-align: justify;
            border-radius: 10px;
        }


    }
}
</style>
<template>
    <div class="monitor-history">
        <Player class="history-player" :src="src"></Player>
        <div class="lists">
            <div class="item" :class="{active: index == showIndex}" v-for="(day, index) of days" :key="index" @click="handleClick(day,index)">
                {{ day }}
            </div>
        </div>
    </div>
</template>
<script>
import Player from "./player.vue"
import Moment from "moment"
export default {
    components: {
        Player
    },
    props: {
        deviceNo: String
    },
    data() {
        return {
            src: null,
            days: [],
            showIndex:null,
        }
    },
    methods: {
        handleClick(day,index) {
            this.showIndex = index
            var params = {
                startTime: day + " 00:00:00",
                endTime: day + " 59:59:59",
                deviceNo: this.deviceNo
            }
            this.$axios.get('screen/open/playbackUrl', { params }).then(res => {
                if (res.data.code == 200) {
                    this.src = res.data.data
                }
            })
        }
    },
    mounted() {
        const now = new Date();
        const moment = Moment(now);
        for (var i = 1; i <= 7; i++) {
            var day = moment.subtract(24, "hour").format('YYYY-MM-DD')
            this.days.push(day);
        }
    }
}
</script>
<style lang="scss">
.monitor-history {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .history-player {
        width: 410px;
    }

    .lists {
        width: 120px;
        .item {
            line-height: 2.5;
            &:nth-child(even) {
                background: rgb(109, 135, 166);
            }
            &.active {
                background: rgb(20, 32, 46);
            }
            background: rgb(77, 103, 136);
            text-align: center;
            color: white;
            cursor: pointer;

        }
    }
}
</style>
<template>
  <div class="main">
    <div class="content">
      <div class="left">
        <div class="left-top">
          <AgeAna orient="left"></AgeAna>
        </div>
        <div class="left-bottom">
          <Stream orient="left"></Stream>
        </div>
      </div>
      <div class="cen">
        <div class="cen_box">
          <div class="bottom_box">
            <div class="top_box">
              <div class="top_left">
                <!-- 日期 -->
                <div class="top_item">
                  <span class="date">{{ formattedDateTime }}</span>
                </div>
                <!-- 空气湿度 -->
                <div class="top_item">
                  <div class="top">
                    <img src="../assets/humidity.png" alt="" />
                  </div>
                  <div class="bottom">
                    {{ weatherData.humidity + "%" }}
                  </div>
                </div>
                <!-- 空气温度 -->
                <div class="top_item">
                  <div class="top">
                    <img src="../assets/temper.png" alt="" />
                  </div>
                  <div class="bottom">
                    {{ weatherData.temperature + "°" }}
                  </div>
                </div>
                <!-- 风速方向 -->
                <div class="top_item">
                  <div class="top">
                    <img src="../assets/wind.png" alt="" />
                  </div>
                  <div class="bottom" v-if="weatherData.windInfo">
                    {{
                      weatherData.windInfo.speed +
                      " " +
                      "km/h" +
                      " " +
                      weatherData.windInfo.direction +
                      "方向"
                    }}
                  </div>
                </div>

              </div>
            </div>
            <div class="right-top">
              <!-- 热力图 -->
              <div class="top_item" @click="handleSwitchHeatMap">
                <div class="top">
                  <img src="../assets/heatmap.png" alt="" />
                </div>
                <div class="bottom">热力图</div>
              </div>
              <!-- 实时监控 -->
              <div class="top_item" @click="handleOpenMonitor">
                <div class="top">
                  <img src="../assets/monitor.png" alt="" />
                </div>
                <div class="bottom">监控</div>
              </div>
            </div>
            <div class="main-space">
              <Monitor :show.sync="monitorShow" :video="videoUrl" :title="videoCode"></Monitor>
              <Hotspot :show.sync="showHotspot" :hotspot="hotspot"></Hotspot>
              <OpenMap ref="map"  @openVideo="handleOpenVideo" @openHotspot="handleOpenHotspot" @mapSwitching="mapSwitching"></OpenMap>
            </div>
            <div class="maps">
              <div class="map-item" :class="{active: mapActive.htyckpy}" @click="handleSwitchMap('htyckpy')">油茶公园</div>
              <div class="map-item" :class="{active: mapActive.htyc}" @click="handleSwitchMap('htyc')">常山国家油茶公园</div>
              <div class="map-item" :class="{active: mapActive.cshtc}" @click="handleSwitchMap('cshtc')">油茶科普物种园</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-top">
          <EventReport orient="right"></EventReport>
        </div>
        <div class="right-bottom">
          <QrRanking orient="right"></QrRanking>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgeAna from "./AgeAna.vue";
import Stream from "./Stream.vue";
import EventReport from "./EventReport.vue";
import QrRanking from "./QrRanking.vue";
import Monitor from "./Monitor.vue"
import Hotspot from "./Hotspot.vue"
import { mapState } from "vuex";
import OpenMap from "./OpenMap.vue";
export default {
  data() {
    return {
      mapActive: {
        htyckpy:false,
        htyc: true,
        cshtc: false
      },
      weatherData: {},
      monitorShow: false, //显示监控画面
      heatMapShow: false, //显示热力图
      camraShow: false, //显示摄像头
      videoUrl: null,
      videoCode: null,
      hotspot: {},
      showHotspot: false
    };
  },
  components: {
    AgeAna,
    Stream,
    EventReport,
    QrRanking,
    OpenMap,
    Monitor,
    Hotspot
  },
  computed: {
    ...mapState(["weatherInfo"]),
    formattedDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  watch: {
    weatherInfo(newVal) {
      if (newVal) {
        this.weatherData = newVal;
      }
    },
  },
  methods: {
    handleSwitchHeatMap() {
      this.heatMapShow = !this.heatMapShow
      if (this.heatMapShow) {
        this.$refs.map.showHeatMap()
      } else {
        this.$refs.map.hideHeatMap()
      }
    },
    handleOpenMonitor() {
      this.camraShow = !this.camraShow
      if (this.camraShow) {
        this.$refs.map.showCameras()
      } else {
        this.$refs.map.showHotspots()
      }
    },
    handleOpenVideo(data) {
      this.monitorShow = true
      this.videoUrl = data.url
      this.videoCode = data.code
    },
    handleOpenHotspot(data) {
      this.showHotspot = true
      this.hotspot = data
    },
    handleSwitchMap(name) {
      this.$refs.map.swtichTileLayer(name)
      for(let p in this.mapActive) {
        this.mapActive[p] = false
      }
      this.mapActive[name] = true
    },
    mapSwitching(name){
      console.log('父组件被触发了');
      this.handleSwitchMap(name)
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  padding: 0 10px;

  .main-space {
    position: relative;
    width: 100%;
    height: 640px
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .cen {
      width: 1000px;
      height: 100%;
      box-sizing: border-box;
      //padding: 20px;
      //padding-bottom: 10px;

      .cen_box {
        width: 100%;
        height: 100%;

        .bottom_box {
          width: 100%;
          height: 100%;
          position: relative;

          .maps {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(25, 25, 26, 0.3) 1062.5%, rgba(33, 100, 156, 0.3) -962.5%);

            .map-item {
              line-height: 43px;
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              width: 520px;
              height: 43px;
              padding: 0 15px;
              //background-color: #2c3e50;
              border-radius: 5px;
              margin: 0 15px;
              color: white;
              background-image: url("../assets/map-off.png");
              background-size: contain;
              background-repeat: no-repeat;
              &.active {
              background-image: url("../assets/map-on.png");
              }
            }
          }

          .right-top {
            position: absolute;
            right: 10px;
            top: 100px;
            //width: 100px;
            padding: 25px 10px 10px;
            //height: 200px;
            z-index: 1;
            background-color: #2c3e50;
            border-radius: 10px;

            .top_item {
              //width: 150px;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              margin-bottom: 15px;;

              .top {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }
              }

              .bottom {
                width: 100%;
                text-align: center;
                line-height: 20px;
                font-family: "content";
                font-size: 12px;
                color: white;
              }
            }
          }

          .top_box {
            width: 100%;
            height: 40px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .top_left {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              position: relative;
              padding: 15px;
              box-sizing: border-box;
            }



            .top_item {
              //width: 150px;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-right: 20px;

              .date {
                font-size: 16px;
                font-weight: bold;
                color: #34ffbf;
              }

              .top {
                width: 30px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 5px;
                }
              }

              .bottom {
                width: 100%;
                text-align: center;
                line-height: 20px;
                font-family: "content";
                font-size: 16px;
                font-weight: bold;
                color: rgb(52, 255, 191);
              }
            }
          }
        }
      }
    }

    .left,
    .right {
      width: 450px;
      height: 100%;

      .left-top,
      .right-top {
        height: 334px;
        width: 100%;
        position: relative;
        // background: brown;
        margin-bottom: 2px;
      }

      .left-bottom,
      .right-bottom {
        height: 314px;
        width: 100%;
        position: relative;
      }
    }
  }
}
</style>
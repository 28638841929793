<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
const removeHeader = 70
const ScreenWidth = 1948
const ScreenHeight = 1080 
const viewWidth = ScreenWidth
const viewHeight = ScreenHeight - removeHeader
export default {
  data() {
    return {
      viewStyle: {
        width: viewWidth + 'px',
        height: viewHeight + 'px',
        transform: 'scale(1)'
      }
    }
  },
  methods: {
    debounce(fn, delay = 500) {
      let timer
      return function () {
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        const ctx = this
        timer = setTimeout(() => {
          timer = null
          fn.apply(ctx, args)
        }, delay)
      }
    },
    getScale() {
      const width = this.getDocWidth()
      const height = this.getDocHeight()
      const w = width / viewWidth
      const h = height / viewHeight
      return w < h ? w : h
    },
    getDocWidth() {
      
      return document.documentElement.clientWidth || document.body.clientWidth;
    },
    getDocHeight() {
      
      return document.documentElement.clientHeight || document.body.clientHeight;
    },
    setScale() {
      const scale = this.getScale()
      this.viewStyle.transform = 'scale(' + scale + ')'
      if (scale < 1) {
        const width = this.getDocWidth()
        let marginSize = (width - viewWidth * scale) / 2
        document.body.style.margin = "0 " + marginSize + "px"
      }
      document.body.style.height = this.viewStyle.height
      document.body.style.width = this.viewStyle.width
      document.body.style.transform = this.viewStyle.transform
      document.body.style.transformOrigin = "left top"
    }
  },
  mounted() {
    this.setScale()
    window.onresize = this.debounce(this.setScale, 1000)
  }
}
</script>


<style lang="scss">
#app {
  font-family: content;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}
</style>
